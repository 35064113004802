import axios from "axios";
import { AUTH_CONTEXT, logout } from "@/api/api";
import { useToast } from "vue-toastification";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
TimeAgo.addDefaultLocale(en);
import moment from "moment";

const { VITE_EMPLOYEE_API, VITE_SETTINGS_API } = import.meta.env;

const toast = useToast();

const screens = ref({
  sm: {
    size: 640,
    point: false,
  },
  md: {
    size: 1000,
    point: false,
  },
  lg: {
    size: 1024,
    point: false,
  },
  xl: {
    size: 1280,
    point: false,
  },
  xs: {
    size: 400,
    point: false,
  },
});

const updateScreenSize = () => {
  Object.keys(screens.value).forEach((key) => {
    screens.value[key].point = window.innerWidth < screens.value[key].size;
  });
};

window.addEventListener("resize", updateScreenSize);

export const helperFunctions = {
  checkNull(arg) {
    return arg || "N/A";
  },
  breakPoints(arg) {
    return arg ? screens.value[arg].point : null;
  },
  removeDuplicates(arr, identifier) {
    if (!arr || !Array.isArray(arr) || !arr.length) return [];
    const seen = new Set();
    const uniqueArray = arr.filter((obj) => {
      const duplicate = seen.has(obj[identifier]);
      seen.add(obj[identifier]);
      return !duplicate;
    });
    return uniqueArray;
  },
  truncateAmount(amount) {
    try {
      // Truncate finalAmount to 2 decimal places without rounding up
      if (amount === undefined || amount === null) {
        return 0;
      }
      const truncatedAmount = Math.trunc(amount * 100) / 100;
      return truncatedAmount;
    } catch (error) {
      console.error("error:", error);
      return 0;
    }
  },
  formatAmount(amount) {
    try {
      if (
        amount === undefined ||
        amount === null ||
        amount === 0 ||
        (typeof amount !== "number" && typeof amount !== "string") ||
        isNaN(Number(amount))
      ) {
        return "₦0.00";
      }
      let formatedAmount = parseInt(amount);
      formatedAmount = amount.toLocaleString("en-NG", {
        style: "currency",
        code: "NG",
        currency: "NGN",
      });

      return formatedAmount;
    } catch (error) {
      console.error("error:", error);
      return 0;
    }
  },
  processNumber(phoneNumber) {
    if (phoneNumber) {
      let phone = phoneNumber;
      let firstDigit = phone.charAt(0);
      if (firstDigit == "0") {
        let formatted = phone.replace("0", "234").replace(/\s+/g, "");
        return String(formatted);
      } else if (firstDigit == 2) {
        let formatted = phone.replace(/\s+/g, "");
        return String(formatted);
      } else if (firstDigit == "+") {
        let formatted = phone.substring(1).replace(/\s+/g, "");
        return String(formatted);
      } else {
        return phoneNumber;
      }
    } else {
      return "";
    }
  },
  truncateObj(obj) {
    try {
      if (obj === undefined || obj === null) {
        return obj;
      }

      let keysArr = [];

      keysArr = Object.keys(obj);

      keysArr.map((b) => {
        //loop through the keys contained in the object
        if (typeof obj[b] === "number") {
          //if its value is a number, truncuate it to 2 decimal places
          obj[b] = this.truncateAmount(obj[b]).toLocaleString();
        }
      });
      return obj;
    } catch (error) {
      throw error;
    }
  },
  resetObj(obj, objName) {
    obj = Object.keys(obj).reduce((acc, curr) => {
      if (obj[curr] === null) return { ...acc, [curr]: null };
      if (typeof obj[curr] === "undefined")
        return { ...acc, [curr]: undefined };
      // if (typeof obj[curr] === "boolean") return { ...acc, [curr]: false }; //Handle Booleans separately
      if (typeof obj[curr] === "string") return { ...acc, [curr]: "" };
      if (typeof obj[curr] === "number") return { ...acc, [curr]: 0 };
      if (obj[curr] instanceof Array) return { ...acc, [curr]: [] };
      if (typeof obj[curr] === "object") return { ...acc, [curr]: {} };
    }, {});
    return obj;
  },
  processDate() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    return today;
  },

  validateEmail(email) {
    if (email) {
      return email.match(
        /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      );
    }
  },

  validatePhone(phone) {
    // if (phone.trim() == "") {
    //   return false;
    // }
    if (phone.length) {
      if (phone.charAt(0) == 0 && phone.length !== 11) {
        return false;
      } else if (phone.charAt(0) == 2 && phone.length !== 13) {
        return false;
      } else if (phone.length < 11) {
        return false;
      } else {
        // errorRules.email = false;
        // errorRules.pin = false;

        return true;
      }
    }
  },

  calculatePercentage(num, per) {
    const result = (Number(num) / 100) * Number(per);
    return result;
  },
  convertDate(inputDate) {
    const date = new Date(inputDate);

    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  },

  formatDateString(date, arg) {
    let formattedDate;
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (date && date.length > 0 && date !== "N/A") {
      let dd = date.slice(8, 10);
      let mm = date.slice(5, 7);

      let yyyy = date.slice(0, 4);
      // if (dd < 10) {
      //   dd = "0" + dd;
      // }
      // if (mm < 10) {
      //   mm = "0" + mm;
      // }
      formattedDate =
        arg === "name"
          ? months[parseInt(mm.toString()) - 1] + " " + dd + ", " + yyyy
          : dd + "/" + mm + "/" + yyyy;
    } else {
      formattedDate = "N/A";
    }

    return formattedDate;
  },

  formatDate(date, style = "Do MMM YYYY") {
    if (!date) return "N/A";
    return moment(date).format(style);
  },

  monthDifferent(dateOne, dateTwo) {
    if (!dateOne || !dateTwo) {
      throw new Error(
        "Invalid date input. Please provide valid moment objects."
      );
    }

    const date1 = moment(dateOne);
    const date2 = moment(dateTwo);

    const monthDiff = date2.diff(date1, "months", true);
    return monthDiff;
  },
  fileToBase64(file) {
    if (!file || !(file instanceof File)) return {};
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  },
  base64ToFile(base64String, filename) {
    const comp_id = window.localStorage.getItem("companyId");

    if (!base64String || typeof base64String !== "string") {
      return {};
    }
    const arr = base64String.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], `${filename}-${comp_id}`, { type: mime });
  },
  base64ToPdf(base64String, fileName = "file.pdf") {
    // Convert Base64 string to a Blob
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(null)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    // Create a link to download the file
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    // Cleanup
    URL.revokeObjectURL(link.href);
  },

  base64ToCsv(base64String, fileName = "file.csv") {
    // Convert Base64 string to a Blob
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(null)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "text/csv" });

    // Create a link to download the file
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    // Cleanup
    URL.revokeObjectURL(link.href);
  },

  async uploadToREST(file, route = "upload") {
    try {
      // Create a FormData object to hold the file
      const formData = new FormData();
      // formData.append("files", file);
      formData.append("operations", "{}");
      formData.append("map", `{"0": ["variables.file"]}`);
      formData.append("files", file);

      // Set the headers if necessary (e.g., for authorization)
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: AUTH_CONTEXT(),
        },
      };

      console.log(VITE_SETTINGS_API, "VITE_SETTINGS_API");

      const baseUrl = VITE_SETTINGS_API?.replace("/graphql", "/files");
      // https://settingsservice-9513d436f9a6e2cc.onporter.run/files

      // Make the POST request to upload the file
      const response = await axios.post(
        `${baseUrl}/${route}`,
        formData,
        config
      );

      // Handle the response from the server
      // console.log("File uploaded successfully:", response.data);
      return response.data.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  },
  async uploadFileToServer(fileToUpload, indemnity) {
    //   return true;
    // console.log(fileToUpload, "TOBEUPLOADED");
    if (fileToUpload && typeof fileToUpload === "string" && !indemnity) {
      return fileToUpload;
    }
    let response;
    const callFunc = async () => {
      if (fileToUpload && !indemnity) {
        const formData = new FormData();
        const operation = "gcpUpload";
        formData.append(
          "operations",
          `{
            "query": "mutation ${operation}($file:Upload!) { ${operation}(file: $file){message data} }"
          }`
        );
        formData.append("map", `{"0": ["variables.file"]}`);
        formData.append("0", fileToUpload);

        try {
          response = await axios.post(
            // "https://employeemgtservice-5a2d98243525f6bf.onporter.run/graphql",
            VITE_EMPLOYEE_API,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: AUTH_CONTEXT(),
                "Apollo-Require-Preflight": "true",
              },
            }
          );
          if (
            response.data &&
            response.data.data &&
            response.data.data.gcpUpload &&
            response.data.data.gcpUpload.data &&
            response.data.data.gcpUpload.data.url
          ) {
            console.log(
              typeof response.data.data.gcpUpload.data.url,
              "FILEFILETYPEURL"
            );
            console.log(response.data.data.gcpUpload.data.url, "FILEURL");
            console.log(
              typeof response.data.data.gcpUpload.data.url,
              "FILETYPE2"
            );

            return await String(response.data.data.gcpUpload.data.url);
          } else if (
            response.data.errors &&
            response.data.errors.length &&
            response.data.errors[0].message === "Token is not valid!"
          ) {
            const refreshed = await refreshTokenFunc();
            if (refreshed) {
              await callFunc(fileToUpload, indemnity);
              return;
            }
            throw new Error(response.data.errors[0].message);
          } else {
            throw new Error(response.data.errors[0].message);
          }
        } catch (err) {
          toast.error(err.message);
          console.log(err.message);
          throw err;
        }
      } else if (fileToUpload && indemnity) {
        const formData = new FormData();
        const operationName = "SubmitIndemityForm";

        // const resolvedIndemnity = await indemnity;
        // Construct GraphQL operation
        const query = `mutation ${operationName}($file: Upload!, $input: IndemityFormInput) {
        ${operationName}(file: $file, input: $input) {
          success
          message
          returnStatus
          token
          data
          privileges
        }
      }`;

        // Append GraphQL operation and map
        // formData.append("operations", JSON.stringify({ query, variables: { file: null, input: null } }));
        formData.append(
          "operations",
          JSON.stringify({ query, variables: { file: null } })
        );
        formData.append("map", JSON.stringify({ 0: ["variables.file"] }));

        // Append file and input
        await formData.append("0", fileToUpload);
        // await formData.append("1", JSON.stringify(resolvedIndemnity.input));

        try {
          response = await axios.post(
            // "https://settingsservice-9513d436f9a6e2cc.onporter.run/graphql",
            VITE_SETTINGS_API,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: AUTH_CONTEXT(),
                "Apollo-Require-Preflight": "true",
              },
            }
          );

          // if (response.data.errors && response.data.errors.length > 0) {
          //   // Handle GraphQL errors
          //   console.error(response.data.errors);
          //   throw new Error("GraphQL Error");
          // }
          if (response.data && response.data.data) {
            return response.data.data[operationName];
          } else if (
            response.data.errors &&
            response.data.errors.length &&
            response.data.errors[0].message === "Token is not valid!"
          ) {
            const refreshed = await refreshTokenFunc();
            if (refreshed) {
              await callFunc(fileToUpload, indemnity);
              return;
            }
            throw new Error(response.data.errors[0].message);
          } else {
            throw new Error(response.data.errors[0].message);
          }
        } catch (error) {
          console.error("Error:", error);
          return null;
        }
      }
      // return "";
    };
    const refreshTokenFunc = async () => {
      try {
        const res = await logout("refresh");
        return res;
      } catch (error) {
        console.error("Error:", error);
        return null;
      }
    };

    return await callFunc();
  },

  flattenObj(ob) {
    // The object which contains the
    // final result
    let result = {};

    // loop through the object "ob"
    for (const i in ob) {
      // We check the type of the i using
      // typeof() function and recursively
      // call the function again
      if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
        const temp = flattenObj(ob[i]);
        for (const j in temp) {
          // Store temp in result
          result[i + "." + j] = temp[j];
        }
      }

      // Else store ob[i] in result directly
      else {
        result[i] = ob[i];
      }
    }
    return result;
  },

  getAvatar(employee) {
    function getColor(index) {
      let n = (index * 0xfffff * 1000000).toString(16);
      return "#" + n.slice(0, 6);
    }

    let name = "",
      color;

    if (employee) {
      let letters = "abcdefghijklmnopqrstuvwxyz";
      const alphabet = letters.split("");
      const empId = employee.job && employee.job.userName;
      const thenum = empId ? Number(empId.replace(/\D/g, "")) : 1;
      const initialOne = String(
        employee &&
        employee.profile &&
        employee.profile.firstName &&
        employee.profile.firstName.charAt(0)
      );
      const initialTwo = String(
        employee &&
        employee.profile &&
        employee.profile.lastName &&
        employee.profile.lastName.charAt(0)
      );
      const num =
        Number(alphabet.indexOf(initialOne.toLowerCase())) +
        Number(alphabet.indexOf(initialTwo.toLowerCase()));
      color = getColor(num);
      // Get color

      employee
        ? (name = `${employee && employee.profile && employee.profile.firstName
            ? employee.profile.firstName.charAt(0)
            : ""
          }${employee && employee.profile && employee.profile.lastName
            ? employee.profile.lastName.charAt(0)
            : ""
          }`)
        : "N/A";
    }

    return {
      color,
      name,
    };
  },

  getFullName(user) {
    if (!user) return "N/A";
    const name = user.firstName + " " + user.lastName;
    return name || "N/A";
  },

  initials(user, name) {
    if (!user && !name) return "N/A";

    if (user) {
      const fName = user.firstName && user.firstName[0];
      const lName = user.lastName && user.lastName[0];

      const strVal = fName + lName;
      return strVal || "N/A";
    }

    if (name) {
      const str = name.split(" ");
      const fName = str[0] && str[0][0];
      const lName = str[1] && str[1][0];
      console.log("str", fName, lName);

      const strVal = fName + (lName ? lName : "");
      return strVal || "N/A";
    }
  },

  // Get rid of the proxy when console.log
  log(arg) {
    console.log(JSON.parse(JSON.stringify(arg)));
  },

  lastDateOfMonth(month, year) {
    if (year && month) {
      const date = new Date(year, month + 1, 0); // Set the date to the first day of the next month
      date.setDate(-1); // Decrement the date by one day
      return date.getDate();
    }
  },

  downloadBase64(base64String, fileName, type, zip = null) {
    let link = document.createElement("a");
    if (zip) {
      link.href = `data:application/zip;base64,${base64String}`;
      link.download = fileName;
      link.click();
      return;
    }
    if (!type || type === "pdf") {
      link.href = `data:application/pdf;base64,${base64String}`;
      link.download = fileName;
      link.click();
      return;
    } else if (type === "csv") {
      link.href = `data:text/csv;base64,${base64String}`;
      link.download = fileName;
      link.click();
      return;
    }

    if (type === "csv") {
      link.href = `data:text/csv;base64,${base64String}`;
      link.download = fileName;
      link.click();
      return;
    }

    link.href = `data:application/vnd.ms-excel;base64,${base64String}`;

    link.download = fileName;
    link.click();
  },

  downloadExcelFromBase64(base64Data, fileName) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  viewPDF(base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl, "_blank");
  },
  printFile(base64String, type) {
    // Create a Blob from the base64 string
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the byte array
    const blob = new Blob([byteArray], {
      type: type === "pdf" ? "application/pdf" : "text/csv",
    });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Open the URL in a new tab
    const newTab = window.open(url, "_blank");

    // Trigger the print dialog when the new tab is loaded
    newTab.onload = function () {
      newTab.print();
    };
  },
  printPDF: (base64Pdf) => {
    this.printFile(base64Pdf, "pdf");
  },
  printCsv: (base64Csv) => {
    this.printFile(base64Csv, "csv");
  },
  relativeTime(date) {
    const timeAgo = new TimeAgo("en-US");
    return timeAgo.format(new Date(date));
  },

  jsonToXlsx(data) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert workbook to an array buffer
    const arrayBuffer = XLSX.write(workbook, { type: "array" });

    // Convert array buffer to Blob
    const blob = new Blob([arrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link and trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "output.xlsx";
    downloadLink.click();
  },
  formatDateTime(dateString) {
    const date = new Date(dateString);

    // Format date
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();

    // Format time
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Construct the formatted string
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}${ampm}`;

    return `${formattedDate} | ${formattedTime}`;
  },

  getDateText(inputDateString) {
    if (
      !inputDateString ||
      typeof inputDateString !== "string" ||
      inputDateString.length == 0
    ) {
      return "N/A";
    }
    const inputDate = new Date(inputDateString);

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
      // second: "numeric",
      // timeZone: "UTC",
    };

    const formattedDate = inputDate.toLocaleDateString("en-US", options);

    return formattedDate;
  },

  copyText(text, message = "Copied!") {
    navigator.clipboard.writeText(text);
    toast.info(message);
  },

  listFormat(list, options) {
    const listOption = {
      style: "long", // 'long', 'short', or 'narrow'
      type: "conjunction", // 'conjunction', 'disjunction', or 'unit'
      ...options,
    };

    const result = new Intl.ListFormat("en", listOption).format(list);
    return result;
  },

  hasData(obj) {
    if (obj === null || obj === undefined) {
      return false; // No data in null or undefined
    }

    if (typeof obj !== "object") {
      return Boolean(obj); // Check if it's a non-empty primitive (e.g., non-empty string, non-zero number)
    }

    if (Array.isArray(obj)) {
      return obj.length > 0; // Arrays have data if their length is greater than zero
    }

    // For objects, check if it has any own properties
    return Object.keys(obj).length > 0;
  },
  async urlToFile(url, fileName = "downloaded-file") {
    try {
      // Fetch the file data from the URL
      const response = await fetch(url);

      // Ensure the response is successful
      if (!response.ok) {
        throw new Error(
          `Failed to fetch the file from URL: ${response.statusText}`
        );
      }

      // Get the response as a Blob
      const blob = await response.blob();

      // Determine the file type (e.g., image/jpeg, application/pdf)
      const fileType = blob.type;

      // Create a File object from the Blob
      const file = new File([blob], fileName, { type: fileType });

      return file;
    } catch (error) {
      console.error("Error converting URL to file:", error);
      throw error;
    }
  },
  getPreviousMonth() {
    const now = new Date();
    const previousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    return previousMonth.toLocaleString('default', { month: 'long' });
  }
};
