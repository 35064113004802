import { useDataStore } from "@/stores/data.js";
import { computed } from "vue";

export const useKycInfo = () => {
  const store = useDataStore();

  const personalInfo = computed(() => {
    const kyc = store.getKyc?.data;
    return kyc?.adminStatus?.status || kyc?.status;
  });

  const companyKyc = computed(() => {
    const kyc = store.getCompanyKyc?.data;
    return {
      dojah: kyc?.statusInformation,
      admin: kyc?.adminStatus,
    };
  });

  const businessInfo = computed(() => {
    const adminStatus = companyKyc.value?.admin;
    const dojahStatus = companyKyc.value?.dojah;
    return (
      adminStatus?.addressVerification?.status ||
      dojahStatus?.addressVerification
    );
  });

  const businessDoc = computed(() => {
    const adminStatus = companyKyc.value?.admin;
    const dojahStatus = companyKyc.value?.dojah;
    return (
      adminStatus?.businessDocuments?.status || dojahStatus?.businessDocuments
    );
  });

  const directorInfo = computed(() => {
    const kyc = store.getDirectors?.data;
    return kyc?.adminStatus?.status || kyc?.status;
  });

  const allPending = computed(() => {
    return [
      personalInfo.value,
      directorInfo.value,
      businessDoc.value,
      businessInfo.value,
    ].every((el) => el == "pending");
  });

  const allVerified = computed(() => {
    return [
      personalInfo.value,
      directorInfo.value,
      businessDoc.value,
      businessInfo.value,
    ].every((el) => el == "verified");
  });

  const overallStatus = computed(() => {
    if (allPending.value) {
      return "pending";
    } else if (allVerified.value) {
      return "verified";
    } else {
      return "not_verified";
    }
  });

  return {
    overallStatus,
    allPending,
    personalInfo,
    businessInfo,
    businessDoc,
    directorInfo,
  };
};
