<template>
    <div class="min-h-screen bg-white flex items-center justify-center p-6">
      <div class="max-w-sm w-full space-y-5 text-center">
        <!-- Illustration -->
        <div class="relative w-44 h-44 mx-auto">
        <img src="@/assets/icons/mobile-prompt.svg?url" alt="">
        </div>
  
        <!-- Text Content -->
        <div class="space-y-4">
          <h1 class="text-4xl font-bold text-gray-900">
            Get the<br />Best Experience!
          </h1>
          
          <p class="text-gray-600 text-lg leading-relaxed">
            For the best experience, use our mobile app.<br />
            The web version works best on desktop.
          </p>
        </div>
  
        <!-- Buttons -->
        <div class="space-y-4 pt-4">
          <button 
            @click="handleTryApp"
            class="focus:outline-none w-full py-[10px] px-6 bg-primary text-white text-lg font-medium rounded-full hover:bg-primary transition-colors duration-200"
          >
            Try the app
          </button>
          
          <button 
            @click="handleContinueWeb"
            class="focus:outline-none w-full py-[10px] px-6 bg-gray-100 text-gray-700 text-lg font-medium rounded-full hover:bg-gray-200 transition-colors duration-200"
          >
            Continue with web view
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  const emit = defineEmits(['close'])
  const handleTryApp = () => {
    // Add logic to redirect to app store or handle app deep linking
    //Implement logic to check for device type and redirect to app or playstore

    console.log('Redirecting to app...')
    openMobile()
  }

  const openMobile = async () => {
  // More reliable platform detection
  const userAgent = navigator.userAgent.toLowerCase();
  
  const isIOS = /iphone|ipad|ipod|mac/.test(userAgent);
  const isAndroid = /android/.test(userAgent);

  // Store URLs
  const appStoreUrl = "https://apps.apple.com/us/app/eazipay-business/id6446281426";
  const playStoreUrl = "https://play.google.com/store/apps/details?id=com.myeazipay.business";

  try {
    if (isIOS) {
      // Try to open the app first (if installed)
      const appScheme = "eazipay://"; // Replace with your app's custom URL scheme
      const timeout = 1000; // Timeout in milliseconds
      
      const openApp = () => {
        const now = Date.now();
        window.location.href = appScheme;
        
        setTimeout(() => {
          if (Date.now() - now < timeout + 100) {
            // App not installed, redirect to App Store
            window.location.href = appStoreUrl;
          }
        }, timeout);
      };
      
      openApp();
    } 
    else if (isAndroid) {
      // Try to open the app first (if installed)
      const intent = "intent://eazipay/#Intent;scheme=eazipay;package=com.myeazipay.business;end";
      
      try {
        window.location.href = intent;
        
        // Fallback to Play Store after a short delay
        setTimeout(() => {
          window.location.href = playStoreUrl;
        }, 500);
      } catch (e) {
        // Direct Play Store fallback if intent fails
        window.location.href = playStoreUrl;
      }
    }
    else {
      // For desktop or unsupported platforms
      const preferredStore = window.matchMedia('(mac-system)').matches ? appStoreUrl : playStoreUrl;
      window.open(preferredStore, '_blank');
    }
  } catch (error) {
    console.error('Error opening store:', error);
    // Fallback to opening in new tab
    window.open(isIOS ? appStoreUrl : playStoreUrl, '_blank');
  }
};
  
  const handleContinueWeb = () => {
    // Add logic to continue with web version
    console.log('Continuing with web version...')
    emit('close')
  }
  </script>